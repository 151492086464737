<template>
  <v-container
    id="my-profile"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Your Twilio Credentials
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="twilioSid"
                    label="Twilio SID"
                    class="purple-input"
                    :append-icon="isTwilioSidVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isTwilioSidVisible ? 'text' : 'password'"
                    @click:append="isTwilioSidVisible = !isTwilioSidVisible"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="twilioAuthToken"
                    label="Twilio Authentication Token"
                    class="purple-input"
                    :append-icon="isTwilioAuthTokenVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isTwilioAuthTokenVisible ? 'text' : 'password'"
                    @click:append="isTwilioAuthTokenVisible = !isTwilioAuthTokenVisible"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="twilioSecret"
                    label="Twilio Secret"
                    class="purple-input"
                    :append-icon="isTwilioSecretVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isTwilioSecretVisible ? 'text' : 'password'"
                    @click:append="isTwilioSecretVisible = !isTwilioSecretVisible"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="twilioApiKey"
                    label="Twilio API Key"
                    class="purple-input"
                    :append-icon="isTwilioApiKeyVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isTwilioApiKeyVisible ? 'text' : 'password'"
                    @click:append="isTwilioApiKeyVisible = !isTwilioApiKeyVisible"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="sendGridKey"
                    label="Send Grid Key"
                    class="purple-input"
                    :append-icon="isTwilioApiKeyVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isTwilioApiKeyVisible ? 'text' : 'password'"
                    @click:append="isTwilioApiKeyVisible = !isTwilioApiKeyVisible"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="fromEmailAddress"
                    label="From Email Address"
                    class="purple-input"
                    :append-icon="isTwilioApiKeyVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isTwilioApiKeyVisible ? 'text' : 'password'"
                    @click:append="isTwilioApiKeyVisible = !isTwilioApiKeyVisible"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="save"
                  >
                    Update
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="isClientIdVisible"
                  :cols="isClientSecretVisible? '6': '12'"
                >
                  <div class="credWrapper">
                    <p>Client Id</p>
                    <div class="cred">
                      <div class="credText">
                        {{ clientId }}
                      </div>
                      <div
                        class="credCopyBtn"
                        @click="onCopy(clientId, 'Client Id copied to your clipboard')"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  v-if="isClientSecretVisible"
                  cols="6"
                >
                  <div class="credWrapper">
                    <p>Client Secret</p>
                    <div class="cred">
                      <div class="credText">
                        {{ clientSecret }}
                      </div>
                      <div
                        class="credCopyBtn"
                        @click="onCopy(clientSecret, 'Client Secret copied to your clipboard')"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </div>
                    </div>
                    <div class="highlightedText">
                      Copy your client secret to a safe location, it will no longer be visible once you navigate away from this page
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
      <v-snackbar
        v-model="snackbar"
      >
        {{ notification }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import Vue from 'vue'
  import Clipboard from 'v-clipboard'

  Vue.use(Clipboard)

  export default {

    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      snackbar: false,
      notification: 'Unknown',
      isOverlayVisible: false,
      username: '',
      twilioSid: '',
      twilioAuthToken: '',
      twilioSecret: '',
      twilioApiKey: '',
      sendGridKey: '',
      fromEmailAddress: '',
      isTwilioSidVisible: false,
      isTwilioApiKeyVisible: false,
      isTwilioAuthTokenVisible: false,
      isTwilioSecretVisible: false,
      clientId: '',
      clientSecret: '',
      isClientIdVisible: false,
      isClientSecretVisible: false,
      user: null,
    }),

    async created () {
      this.usermail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async setUserData (profileResponse) {
        this.user = profileResponse
        this.username = profileResponse.Username || ''
        this.twilioSid = profileResponse.TwilioSID || ''
        this.twilioAuthToken = profileResponse.TwilioToken || ''
        this.twilioSecret = profileResponse.TwilioSecret || ''
        this.twilioApiKey = profileResponse.TwilioApiKey || ''
        this.clientId = profileResponse.ClientID || ''
        this.sendGridKey = profileResponse.SGApiKey || ''
        this.fromEmailAddress = profileResponse.FromEmail || ''
        this.isClientIdVisible = true
      },
      async save () {
        const isFormValid = this._checkFormValidity()
        if (!isFormValid) {
          this.isOverlayVisible = false
          return
        }
        this.isOverlayVisible = true
        const payload = {
          username: this.username,
          TwilioAuthication: this.twilioSid,
          TwilioToken: this.twilioAuthToken,
          TwilioSecret: this.twilioSecret,
          TwilioApiKey: this.twilioApiKey,
        }
        // If both the fields are have values, then we need to send the SendGrid Key and From Email Address
        if (this.sendGridKey.length > 0 && this.fromEmailAddress.length > 0) {
          payload.SGApiKey = this.sendGridKey
          payload.FromEmail = this.fromEmailAddress
        }
        try {
          const response = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}updatetwiliocreds?code=${this.apiCode}`, payload)
          if (response.data.ClientID && response.data.ClientID.length) {
            this.clientId = response.data.ClientID
            this.isClientIdVisible = true
          }
          if (response.data.ClientSecret && response.data.ClientSecret.length) {
            this.clientSecret = response.data.ClientSecret
            this.isClientSecretVisible = true
          }
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },
      onCopy (textToCopy, textToShow) {
        this.$clipboard(textToCopy)
        this.notification = textToShow
        this.snackbar = true
      },
      async initialize () {
        try {
          this.isOverlayVisible = true
          const profileResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}userid?code=${this.apiCode}`)
          this.setUserData(profileResponse.data)
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          throw error
        }
      },
      _checkFormValidity () {
        // None of the fields are mandatory
        // But  either SGApiKey or FromEmail has a value - the other must have one. They can both be blank
        if (this.sendGridKey.length > 0 && this.fromEmailAddress.length === 0) {
          this.notification = 'From Email Address is required if Send Grid Key is provided'
          this.snackbar = true
          return false
        }
        if (this.fromEmailAddress.length > 0 && this.sendGridKey.length === 0) {
          this.notification = 'Send Grid Key is required if From Email Address is provided'
          this.snackbar = true
          return false
        }
        // If formemailaddress is provided, it must be a valid email address
        if (this.fromEmailAddress.length > 0 && !this._isValidEmail(this.fromEmailAddress)) {
          this.notification = 'From Email Address is not a valid email address'
          this.snackbar = true
          return false
        }
        return true
      },
      _isValidEmail (email) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        return emailRegex.test(email)
      },
    },
  }

</script>

<style lang="scss" scoped>
  .credWrapper {
    .cred {
      margin-top: -6px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(234, 234, 234, 0.761);
      border-radius: 6px;
      // padding: 8px 25px;
      .credText {
      padding: 8px 25px;

        font-size: 0.9rem;
        margin-right: 2rem;
      }
      .credCopyBtn {
      padding: 8px 55px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        * {
          color: white;
        }
        cursor: pointer;
      }
    }
    .highlightedText {
      margin-top: 10px;
      padding: 8px 35px;
      font-weight: 600;
      background-color: rgb(255, 183, 49);
      color: white;
      font-size: 0.9rem;
    }
  }
</style>
